module.exports.COPY_ICON = `<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
  <path d="m16 1a2 2 0 0 0-2 2v23a2 2 0 0 0 2 2h21a2 2 0 0 0 2-2v-23a2 2 0 0 0-2-2h-21zm2 4h17v19h-17v-19z"/>
  <path d="m3 12a2 2 0 0 0-2 2v23a2 2 0 0 0 2 2h21a2 2 0 0 0 2-2v-23a2 2 0 0 0-2-2h-21z"/>
</svg>`;

module.exports.CHECK_ICON = `<svg style="stroke-linecap: round" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
 <path d="m15 31 21-21m-32 10 11 11" stroke-width="6"/>
</svg>`;

module.exports.CAUTION_ICON = `<svg width="400" height="400" viewBox="0 0 106 106" xmlns="http://www.w3.org/2000/svg">
 <path d="m53 11.1a3.98 3.97 0 0 0-0.129 0.0016 3.98 3.97 0 0 0-3.33 1.99l-43.8 75.8a3.98 3.97 0 0 0 3.44 5.96h87.6a3.98 3.97 0 0 0 3.44-5.96l-43.8-75.8a3.98 3.97 0 0 0-3.42-1.99z" fill="#ed5f58"/>
 <path d="m50.9 35.2a4.03 4.03 0 0 0-4.03 4.03v23.1a4.03 4.03 0 0 0 4.03 4.03h4.13a4.03 4.03 0 0 0 4.03-4.03v-23.1a4.03 4.03 0 0 0-4.03-4.03z" fill="#cad2e6"/>
 <path d="m53 71.2c-3.88 0-7.11 3.23-7.11 7.11s3.23 7.11 7.11 7.11 7.11-3.23 7.11-7.11-3.23-7.11-7.11-7.11z" fill="#cad2e6"/>
</svg>`;

module.exports.CHAT_ICON = `<svg width="400" height="400" viewBox="0 0 106 106" xmlns="http://www.w3.org/2000/svg">
 <defs>
  <linearGradient id="chat-b" x1="52.4" x2="52.4" y1="125" y2="43.3" gradientTransform="translate(.254 -.16)" gradientUnits="userSpaceOnUse">
   <stop stop-color="#b24cff" offset="0"/>
   <stop stop-color="#00c2f2" offset="1"/>
  </linearGradient>
  <linearGradient id="chat-a" x1="73.4" x2="73.4" y1="70.1" y2="93.5" gradientTransform="translate(.254 -.16)" gradientUnits="userSpaceOnUse">
   <stop stop-color="#4b5a80" offset="0"/>
   <stop stop-color="#7b6ffb" offset=".5"/>
   <stop stop-color="#00c2f2" offset="1"/>
  </linearGradient>
 </defs>
 <path d="m60.5 74.6c6.34 7.54 14.9 13.5 24.6 18.1a2.19 2.19 0 0 0 2.84-3.08c-3.26-5.56-6.02-9.95-7.97-15z" fill="url(#chat-a)"/>
 <path d="m9.26 13.1a3.97 3.97 0 0 0-3.97 3.97v54.2a3.97 3.97 0 0 0 3.97 3.97h87.5a3.97 3.97 0 0 0 3.97-3.97v-54.2a3.97 3.97 0 0 0-3.97-3.97z" fill="url(#chat-b)"/>
 <g fill="#4b5a80">
  <path d="m36.8 21.8a7.45 7.44 0 0 0-0.744 0.0206 7.45 7.44 0 0 0-6.85 8 7.45 7.44 0 0 0 8 6.84 7.45 7.44 0 0 0 6.85-8 7.45 7.44 0 0 0-7.26-6.86z"/>
  <path d="m69.3 21.8a7.45 7.44 0 0 0-0.744 0.0206 7.45 7.44 0 0 0-6.85 8 7.45 7.44 0 0 0 8 6.84 7.45 7.44 0 0 0 6.85-8 7.45 7.44 0 0 0-7.26-6.86z"/>
  <path d="m33.3 49.2a3.97 3.97 0 0 0-2.11 0.613 3.97 3.97 0 0 0-1.24 5.48c4.81 7.63 13.9 11.1 22.7 11.1 8.86 0.0276 18.1-3.37 23.4-10.9a3.97 3.97 0 0 0-0.965-5.54 3.97 3.97 0 0 0-5.54 0.963c-3.41 4.84-10.1 7.56-16.9 7.54-6.77-0.0211-13.1-2.78-16-7.4a3.97 3.97 0 0 0-3.38-1.85z"/>
 </g>
</svg>`;