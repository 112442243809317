import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as styles from './typedoc.module.scss';
import { classNames } from '../../../utils';
import { Extensions, languages, RyuseiLight } from '@ryusei/light';
import { syncRemark } from './remark';
import Source from '../../../assets/images/svg/source.svg';


RyuseiLight.register( Object.values( languages ).map( language => language() ) );
RyuseiLight.compose( Extensions );


const SOURCE_URL = 'https://github.com/ryuseijs/ryusei-code/tree/master/src/js/';

export function TypeDoc( {
  group = 'index',
  name,
  kindString = 'Method',
  isStatic = false,
  includeProtected,
} ) {
  const content  = queryContent();
  const children = findChild( content.children, group );

  if ( children ) {
    const data = findChild( children.children, name );

    if ( data ) {
      const filtered = filter( data.children, { includeProtected, kindString, isStatic } );

      if ( filtered.length ) {
        return render( filtered );
      }
    }
  }

  return null;
}

function queryContent() {
  const data = useStaticQuery( query );
  return JSON.parse( data.typedoc.internal.content );
}

function findChild( children, name ) {
  if ( children ) {
    return children.find( child => child.name === name );
  }

  return null;
}

function filter( items, options ) {
  const { kindString, isStatic: _isStatic, includeProtected } = options;

  return items.filter( item => {
    const { isProtected, isPrivate, isStatic } = item.flags;
    const { signatures = [] } = item;

    if ( item.name === 'mount' ) {
      return false;
    }

    if ( ! isStatic !== ! _isStatic ) {
      return false;
    }

    if ( isProtected && ! includeProtected ) {
      return false;
    }

    if ( isPrivate ) {
      return false;
    }

    if ( signatures[ 0 ] && findTag( signatures[ 0 ].comment, 'internal' ) ) {
      return false;
    }

    if ( kindString ) {
      if ( Array.isArray( kindString ) ) {
        return kindString.includes( item.kindString );
      }

      return kindString === item.kindString;
    }

    return true;
  } );
}

function render( items ) {
  return (
    <>
      { items.map( ( item, index ) => {
        const { kindString } = item;
        const isMethod   = kindString === 'Method';
        const isProperty = kindString === 'Property';

        return (
          <section key={ item.id }>
            <header className={ styles.typedocHeader }>
              <h3 className={ classNames(
                isMethod ? styles.typedocHeadingMethod : '',
                isProperty ? styles.typedocHeadingProperty : ''
              ) }>
                <code>{ `${ item.name }${ isMethod ? '()' : '' }` }</code>
              </h3>

              { isMethod && renderSource( item ) }
            </header>

            { renderMethod( item ) }
            { renderProperty( item ) }
            { renderDescription( item ) }
            { renderParams( item ) }
            { renderReturns( item ) }

            { index !== items.length - 1 && <hr/> }
          </section>
        );
      } ) }
    </>
  );
}

function renderSource( item ) {
  const { sources = [] } = item;
  const [ source ] = sources;

  if ( source ) {
    return (
      <a
        href={ `${ SOURCE_URL }/${ source.fileName }#L${ source.line }` }
        rel="noopener noreferrer"
        target="_blank"
        className={ styles.typedocSource }
        aria-label="View the source code"
      >
        <Source />
      </a>
    );
  }

  return null;
}

function renderMethod( item ) {
  if ( item.kindString === 'Method' ) {
    const args       = buildArgs( item );
    const returns    = buildReturnType( item );
    const typeParams = buildTypeParams( item );

    return (
      <p className={ styles.typedocDetail }>
        <code>
          { item.flags.isProtected && <span className={ styles.typedocKeyword }>protected </span> }
          { `${ item.name }${ typeParams }(${ args ? ` ${ args } ` : '' }): ${ returns }` }
        </code>
      </p>
    );
  }

  return null;
}

function renderProperty( item ) {
  if ( item.kindString === 'Property' ) {
    const { flags: { isReadonly }, comment } = item;
    const { tags = [] } = comment;
    const hasReadOnly = tags.find( tag => tag.tag === 'readonly' );
    const readOnly    = isReadonly || hasReadOnly;
    const type        = buildType( item.type );

    return (
      <p className={ styles.typedocDetail }>
        <code>
          { item.flags.isProtected && <span className={ styles.typedocKeyword }>protected </span> }
          { readOnly && <span className={ styles.typedocKeyword }>readonly </span> }
          { `${ item.name }${ type ? `: ${ type }` : '' }` }
        </code>
      </p>
    );
  }

  return null;
}

function renderDescription( item ) {
  let { signatures } = item;
  let html = '';

  if ( ! signatures ) {
    if ( item.getSignature || item.setSignature ) {
      [ item.getSignature, item.setSignature ].forEach( ( signatures, index ) => {
        if ( signatures ) {
          const [ signature ] = signatures;

          html += `<div class="${ styles.typedocAccessor }">`;
          html += `<div class="${ styles.typedocAccessorType }"><span>${ index === 0 ? 'get' : 'set' }</span></div>`;
          html += `<div class="${ styles.typedocAccessorContent }">`;
          html += commentToHtml( signature.comment );
          html += `</div>`;
          html += `</div>`;
        }
      } );
    } else {
      if ( item.comment ) {
        html += commentToHtml( item.comment );
      }
    }
  } else {
    const [ signature ] = signatures;
    const { comment = {} } = signature;
    html += commentToHtml( comment );
  }

  if ( html ) {
    return (
      <div dangerouslySetInnerHTML={ { __html: html } } />
    );
  }

  return null;
}

function commentToHtml( comment ) {
  let html = '';

  const { shortText, text, tags } = comment;

  if ( shortText ) {
    html += syncRemark( shortText );
  }

  if ( text ) {
    html += syncRemark( text );
  }

  if ( tags ) {
    const example = findTag( comment, 'example' );

    if ( example ) {
      html += syncRemark( example.text );
    }
  }

  return html;
}

function renderParams( item ) {
  if ( ! item.signatures ) {
    return null;
  }

  const [ signature ] = item.signatures;
  const { parameters } = signature;

  if ( parameters ) {
    return (
      <>
        <h4>Params</h4>
        <table className={ styles.typedocParamsTable }>
          <tbody>
            { parameters.map( parameter => (
              <tr key={ parameter.id }>
                <th><code>{ parameter.name }</code></th>
                <td dangerouslySetInnerHTML={ {
                  __html: syncRemark( parameter.comment.text ),
                } } />
              </tr>
            ) ) }
          </tbody>
        </table>
      </>
    );
  }

  return null;
}

function buildTypeParams( item ) {
  if ( ! item.signatures ) {
    return '';
  }

  const [ signature ] = item.signatures;
  const { typeParameter } = signature;

  if ( typeParameter ) {
    return `<${ typeParameter.map( param => param.name ).join( ', ' ) }>`;
  }

  return '';
}

function renderReturns( item ) {
  if ( ! item.signatures ) {
    return null;
  }

  const [ signature ] = item.signatures;
  const { comment } = signature;

  if ( comment && comment.returns ) {
    return (
      <>
        <h4>Return</h4>
        <p dangerouslySetInnerHTML={ { __html: syncRemark( comment.returns ) } } />
      </>
    );
  }

  return null;
}

function buildArgs( item ) {
  const [ signature ] = item.signatures;
  const { parameters } = signature;

  if ( parameters ) {
    return parameters.map( parameter => {
      const optional = parameter.flags.isOptional ? '?' : '';
      return `${ parameter.name }${ optional }: ${ buildType( parameter.type ) }`;
    } ).join( ', ' );
  }

  return '';
}

function buildReturnType( item ) {
  const [ signature ] = item.signatures;

  if ( signature.type ) {
    if ( signature.type.checkType ) {
      const { checkType, extendsType, trueType, falseType } = signature.type;
      return `${ buildType( checkType ) } extends ${ buildType( extendsType ) }`
        + ` ? ${ buildType( trueType ) } : ${ buildType( falseType ) }`;
    }

    return buildType( signature.type );
  }

  return '';
}

// { type, types?, name?, typeArguments?, elementType? }
function buildType( type ) {
  const { type: typeName } = type;

  if ( type.declaration ) {
    return buildDeclaration( type.declaration );
  }

  if ( typeName === 'union' ) {
    return type.types.map( buildType ).join( ' | ' );
  }

  if ( typeName === 'intrinsic' ) {
    return type.name;
  }

  if ( typeName === 'reference' && type.name !== '__module' ) {
    if ( type.typeArguments ) {
      return `${ type.name }<${ type.typeArguments.map( child => buildType( child ) ).join( ', ' ) }>`;
    } else {
      return type.name;
    }
  }

  if ( typeName === 'array' ) {
    const { elementType } = type;
    const types = buildType( elementType );

    return elementType.type === 'union' ? `Array<${ types }>` : `${ types }[]`;
  }

  return '';
}

function buildDeclaration( declaration ) {
  const { children, signatures } = declaration;

  if ( children ) {
    return `{ ${ children.map( child => `${ child.name }: ${ buildType( child.type ) }` ).join( ', ' ) } }`;
  }

  if ( signatures ) {
    const [ signature ] = signatures;

    if ( signature.kindString === 'Call signature' ) {
      return `() => ${ signature.type.name }`;
    }
  }

  return '';
}

function findTag( comment, name ) {
  if ( comment ) {
    const { tags } = comment;

    if ( tags ) {
      return tags.find( tag => tag.tag === name );
    }
  }

  return null;
}



const query = graphql`
  query {
    typedoc {
      id
      source {
        id
        kind
        name
      }
      internal {
        content
      }
    }
  }
`;