// extracted by mini-css-extract-plugin
export var typedocHeader = "typedoc-module--typedoc-header--1kHgW";
export var typedocHeadingMethod = "typedoc-module--typedoc-heading--method--280v7";
export var typedocHeadingProperty = "typedoc-module--typedoc-heading--property--Zn4_-";
export var typedocDetail = "typedoc-module--typedoc-detail--eqMrs";
export var typedocParamsTable = "typedoc-module--typedoc-params-table--1rH_z";
export var typedocAccessor = "typedoc-module--typedoc-accessor--1CjKx";
export var typedocAccessorType = "typedoc-module--typedoc-accessor-type--1IC6O";
export var typedocAccessorContent = "typedoc-module--typedoc-accessor-content--16jNf";
export var typedocKeyword = "typedoc-module--typedoc-keyword--1JcFl";
export var typedocSource = "typedoc-module--typedoc-source--3e7Mv";