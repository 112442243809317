import { remark } from 'remark';
import remarkHtml from 'remark-html';
import remarkGfm from 'remark-gfm';
import visit from 'unist-util-visit';
import * as boxStyles from '../Box/box.module.scss';
import { CAUTION_ICON, CHECK_ICON, COPY_ICON, CHAT_ICON } from '../../../constants/icons';
import { RyuseiLight } from '@ryusei/light';


export function syncRemark( text ) {
  return remark()
    .use( remarkBox )
    .use( remarkHighlight )
    .use( remarkGfm )
    .use( remarkHtml )
    .processSync( text )
    .toString();
}

function remarkBox() {
  return markdownAST => visit( markdownAST, 'html', node => {
    const { value } = node;
    const caution   = '<div class="caution">';
    const info      = '<div class="info">';
    const isCaution = value.startsWith( caution );
    const isInfo    = value.startsWith( info );

    if ( isCaution || isInfo ) {
      const inner = value.replace( isCaution ? caution : info, '' ).replace( '</div>', '' );
      let html = '';

      html += `<div class="${ boxStyles.box }">`;
      html += `<div class="${ boxStyles.boxIcon }">`;
      html += isCaution ? CAUTION_ICON : CHAT_ICON;
      html += `</div>`;
      html += `<div class="${ boxStyles.boxContent }">${ inner }</div>`;
      html += `</div>`;

      node.value = html;
    }
  } );
}

function remarkHighlight() {
  return markdownAST => visit( markdownAST, 'code', codeVisitor );
}

function codeVisitor( node ) {
  const language    = node.lang || 'js';
  const ryuseiLight = new RyuseiLight( {
    language,
    copy        : {
      html      : COPY_ICON,
      activeHtml: CHECK_ICON,
    },
    languageName: true,
  } );

  const raw = RyuseiLight.tokenize( node.value, language )
    .map( line => line.map( token => token[ 1 ] ).join( '' ) )
    .join( '\n' );

  const html = `
    <div class="ryuseilight-wrapper ginga">
      ${ ryuseiLight.html( node.value ) }
      <pre class="ryuseilight-raw" hidden>${ raw.replace( /&/g, '&amp;' ).replace( /</g, '&lt;' ) }</pre>
    </div>
  `.trim();

  node.type     = 'html';
  node.value    = html;
  node.children = [];
}